import { mapState } from 'vuex'
import { setCookie } from '~/common/helpers/cookie'

export default {
  data() {
    return {
      widgetId: '',
      smartCaptcha: null,
    }
  },
  computed: {
    ...mapState('core', ['config']),
  },
  methods: {
    captchaCallback(token, callback) {
      const isDebug = localStorage.getItem('debug') === '1'
      if (isDebug) {
        console.log('token', token)
      }
      if (token) {
        setCookie('rabotut:user:captchaToken', token, {
          domain: this.config.cookieDomain,
        })
        if (callback) {
          callback()
        }
      } else {
        console.error('Captcha token is not define')
      }
    },

    execute() {
      this.smartCaptcha.execute()
    },

    renderCaptcha(callback) {
      const isDebug = localStorage.getItem('debug') === '1'
      if (isDebug) {
        console.log('callback', callback)
      }
      if (process.client) {
        const container = document.getElementById('captcha-container')
        if (isDebug) {
          console.log('container', container)
        }
        if (container) {
          if (this.smartCaptcha) {
            if (isDebug) {
              console.log('this.smartCaptcha', this.smartCaptcha)
            }
            this.widgetId = this.smartCaptcha.render('captcha-container', {
              sitekey: 'ysc1_b89aTTqak0beE7SB6RiUPaXR8tMXrtL2CfG1PNHl01d93b06',
              callback: (token) => this.captchaCallback(token, callback),
            })
            if (isDebug) {
              console.log('this.widgetId', this.widgetId)
            }
          }
        }
      }
    },
  },
  mounted() {
    const isDebug = localStorage.getItem('debug') === '1'
    if (isDebug) {
      console.log('window.smartCaptcha', window.smartCaptcha)
    }
    if (!this.smartCaptcha && window.smartCaptcha) {
      this.smartCaptcha = window.smartCaptcha
    }
  },
}
