export const state = () => ({
  config: {},
  subDomain: '',
  protocol: '',
  city: {
    cityFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    city: 'Москва',
    domain: '',
  },
})

export default state
