//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VModelMixin from '~/common/mixins/VModelMixin'

export default {
  name: 'BaseCheckbox',
  mixins: [VModelMixin],
  props: {
    id: {
      type: String,
      default: 'checkbox',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    checkboxClasses() {
      const baseClass = 'checkbox'
      const res = [baseClass]
      if (this.disabled) {
        res.push('disabled')
      }
      if (this.model) {
        res.push('checked')
      }
      return res
    },
  },
}
