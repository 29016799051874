var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-start content-center"},[_c('ul',{class:[
      (" " + (_vm.modal && 'hidden') + " lg:flex flex-col justify-start flex-wrap content-between py-3 lg:p-0 m-0 text-center lg:text-left menu__link-box"),
      _vm.classesUl ]},_vm._l((_vm.linksFooter),function(link,index){return _c('li',{key:("horisontal-" + index),class:[
        'pb-1 text-sm mr-0',
        _vm.linksFooter.length - 1 === index ? 'lg:pr-0' : 'lg:pr-7' ],on:{"click":function($event){return _vm.sendAnalytic(link)}}},[(link.link !== '/partners')?_c('nuxt-link',{class:[
          _vm.classesLi,
          'block lg:inline-block cursor-pointer menu__link' ],attrs:{"to":link.link,"target":link.target}},[_vm._v("\n        "+_vm._s(link.title)+"\n      ")]):_vm._e(),_vm._v(" "),(link.link === '/partners')?_c('a',{class:[
          _vm.classesLi,
          'block lg:inline-block cursor-pointer menu__link' ],attrs:{"href":"https://rabotut.ru/partners"}},[_vm._v("\n        "+_vm._s(link.title)+"\n      ")]):_vm._e()],1)}),0),_vm._v(" "),(_vm.modal)?_c('svg-icon',{staticClass:"block lg:hidden pr-2 cursor-pointer logo-icon not-outlined",attrs:{"name":"menu"},on:{"click":_vm.onMenu}}):_vm._e(),_vm._v(" "),(_vm.modal)?_c('ul',{class:[
      "flex flex-col justify-start flex-wrap content-between bg-white z-30 w-full pb-5 m-0",
      _vm.classesModal,
      'menu__link-box' ]},_vm._l((_vm.links),function(link,index){return _c('li',{key:("vertical-" + index),class:[
        'cursor-pointer p-2 w-full text-center',
        _vm.classesLi,
        'menu__link' ]},[(link.link !== '/partners')?_c('nuxt-link',{staticClass:"block",attrs:{"to":link.link,"target":link.target}},[_vm._v("\n        "+_vm._s(link.title)+"\n      ")]):_vm._e(),_vm._v(" "),(link.link === '/partners')?_c('a',{class:[
          _vm.classesLi,
          'block lg:inline-block cursor-pointer menu__link' ],attrs:{"href":"https://rabotut.ru/partners"}},[_vm._v("\n        "+_vm._s(link.title)+"\n      ")]):_vm._e()],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }