export default {
  setList: (state, payload) => {
    state.list = payload
  },
  setListMeta: (state, payload) => {
    state.listMeta = payload
  },
  setHotList: (state, payload) => {
    state.hotList = payload
  },
  setMapData: (state, payload) => {
    state.mapData = payload
  },
  setCurrent: (state, payload) => {
    state.current = payload
  },
  setLoading: (state, payload) => {
    state.loading = !!payload
  },
}
