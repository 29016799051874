//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VModelMixin from '~/common/mixins/VModelMixin'

export default {
  name: 'BaseInput',
  mixins: [VModelMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    star: {
      type: Boolean,
      default: false,
    },
    innerLabel: {
      type: String,
      default: '',
    },
    message: {
      type: [Object, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'info', // error, success
      validator: (value) => {
        return ['error', 'info', 'success'].includes(value)
      },
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        const result = ['text', 'number', 'date', 'tel'].includes(value)
        if (!result) {
          return value
        }
        return true
      },
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    solo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [Number, String],
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    rootClasses() {
      const baseClass = 'control'
      const res = [baseClass]
      if (this.disabled) {
        res.push('disabled')
      }
      if (this.status) {
        res.push(`control--status-${this.status}`)
      }
      if (this.iconLeft || this.$slots.iconLeft) {
        res.push('has-icon-left')
      }
      if (this.iconRight || this.$slots.iconRight) {
        res.push('has-icon-right')
      }
      if (this.solo) {
        res.push('solo')
      }
      return res
    },
    inputClasses() {
      const baseClass = 'input'
      const res = [baseClass]
      res.push('jumbotron__input')
      if (this.iconLeft || this.$slots.iconLeft) {
        res.push('has-icon-left')
      }
      if (this.iconRight || this.$slots.iconRight) {
        res.push('has-icon-right')
      }
      return res
    },
  },
  methods: {
    onClear() {
      this.$emit('input', '')
    },
  },
}
