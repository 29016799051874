import BANNER_BY_SLOT from '~/services/api/graphql/query/bannerBySlot'

export default {
  getBannersSlotId({ commit }, payload) {
    if (process.client) {
      return new Promise((resolve, reject) => {
        this.$graphql.api_content
          .request(BANNER_BY_SLOT, { slot: payload })
          .then((res) => {
            if (res.bannerBySlot) {
              const banner = res?.bannerBySlot
              commit('fillCurrentBanner', banner)
              resolve(banner)
            }
          })
          .catch((e) => {
            reject(e)
            console.error(e)
          })
      })
    }
  },
}
