import { gql } from 'nuxt-graphql-request'

export const CREATE_REPLY = gql`
  mutation createReply($form: ReplyInputType!) {
    createReply(form: $form) {
      ... on ReplyType {
        id
        status
        contacts {
          email
          fullName
          phone
        }
        source
        positionId
        ownerId
        companyId
        departmentId
        notCallCount
        cityAdsClick
        actionPayClick
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`

export default CREATE_REPLY
