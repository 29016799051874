import { set, pickBy } from 'lodash'
import { LOAD_VACANCIES } from '~/services/api/graphql/query/loadVacancies'
import LOAD_VACANCY_MAP from '~/services/api/graphql/query/loadVacancyMap'
import { camelize } from '~/common/helpers/keysConverter'

export default {
  loadList({ commit, rootState }, payload) {
    commit('setLoading', true)
    const cityFiasId = rootState?.core?.city?.cityFiasId
    if (!cityFiasId) {
      throw new Error('city is not define')
    }
    const requestVariables = {
      filter: {
        cityFiasId,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    }
    if (payload) {
      if (payload.search && payload?.search?.length !== 1) {
        requestVariables.filter.search = payload.search
      }
      if (payload.position?.key) {
        requestVariables.filter.position = payload.position.key
      }
      if (payload.employment?.key) {
        requestVariables.filter.employment = payload.employment.key
      }
      if (payload.metro?.title) {
        requestVariables.filter.metro = payload.metro.title
      }
      if (payload.tag) {
        requestVariables.filter.tag = payload.tag
      }
      // if (payload.salaryFrom) {
      //   requestVariables.filter.salaryFrom = +payload.salaryFrom
      // }
      if (payload.limit) {
        requestVariables.pagination.limit = payload.limit
      }
      if (payload.offset) {
        requestVariables.pagination.offset = payload.offset
      }
      if (payload.salaryFrom) {
        set(
          requestVariables,
          'filter.salary.from',
          parseInt?.(payload?.salaryFrom || 0, 10)
        )
      }
      if (payload.salaryTo) {
        set(
          requestVariables,
          'filter.salary.to',
          parseInt?.(payload?.salaryTo || 0, 10)
        )
      }
    }
    return this.$graphql.api_v2
      .request(LOAD_VACANCIES, requestVariables)
      .then((res) => {
        if (res?.publicVacancyList) {
          commit('setList', res.publicVacancyList?.data)
          commit('setListMeta', res.publicVacancyList?.meta)
          return Promise.resolve(res.publicVacancyList?.data)
        }
        return Promise.reject(new Error("can't load vacancies"))
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  loadHotVacancies({ commit, rootState }, payload) {
    commit('setLoading', true)
    const cityFiasId = rootState?.core?.city?.cityFiasId
    const requestVariables = {
      filter: {
        // city: rootState.user.city || 'Москва',
        tag: 'HOT',
        cityFiasId,
      },
      pagination: {
        limit: 6,
        offset: 0,
      },
    }
    if (payload) {
      // if (payload.search) {
      //   requestVariables.filter.search = payload.search
      // }
      // if (payload.position?.key) {
      //   requestVariables.filter.position = payload.position.key
      // }
      // if (payload.employment?.key) {
      //   requestVariables.filter.employment = payload.employment.key
      // }
      // if (payload.metro?.title) {
      //   requestVariables.filter.metro = payload.metro.title
      // }
      if (payload.limit) {
        requestVariables.pagination.limit = payload.limit
      }
      if (payload.offset) {
        requestVariables.pagination.offset = payload.offset
      }
      if (payload.salaryFrom) {
        set(
          requestVariables,
          'filter.salary.from',
          parseInt?.(payload?.salaryFrom || 0, 10)
        )
      }
      if (payload.salaryTo) {
        set(
          requestVariables,
          'filter.salary.to',
          parseInt?.(payload?.salaryTo || 0, 10)
        )
      }
    }

    return this.$graphql.api_v2
      .request(LOAD_VACANCIES, requestVariables)
      .then((res) => {
        if (res?.publicVacancyList) {
          commit('setHotList', res.publicVacancyList?.data)
          return Promise.resolve(res.publicVacancyList?.data)
        }
        return Promise.reject(new Error("can't load vacancies"))
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  loadMap({ commit, rootState }, payload) {
    commit('setLoading', true)
    const cityFiasId = rootState?.core?.city?.cityFiasId

    const requestVariables = {
      filter: {
        // city: rootState.user.city || 'Москва',
        cityFiasId,
      },
    }
    if (payload) {
      if (payload.position) {
        requestVariables.filter.position = payload.position
      }
      if (payload.employment) {
        requestVariables.filter.employment = payload.employment
      }
      if (payload.metro) {
        requestVariables.filter.metro = payload.metro
      }
      // if (payload.salaryFrom) {
      //   requestVariables.filter.salaryFrom = +payload.salaryFrom
      // }
      if (payload.salaryFrom) {
        set(
          requestVariables,
          'filter.salary.from',
          parseInt?.(payload?.salaryFrom || 0, 10)
        )
      }
      if (payload.salaryTo) {
        set(
          requestVariables,
          'filter.salary.to',
          parseInt?.(payload?.salaryTo || 0, 10)
        )
      }
      if (payload.tag) {
        requestVariables.filter.tag = payload.tag
      }
    }
    if (payload?.intercept) {
      requestVariables.filter = {
        intercept: JSON.stringify(payload.intercept.geometry),
      }
    }
    return this.$graphql.api_v2
      .request(LOAD_VACANCY_MAP, requestVariables)
      .then((res) => {
        if (res?.publicVacancyMap) {
          commit('setMapData', res.publicVacancyMap)
          return Promise.resolve(res.publicVacancyMap)
        }
        return Promise.reject(new Error("can't load vacancies"))
      })
      .catch((e) => {
        return Promise.reject(new Error(e))
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  async loadServerList({ commit, rootState }, payload) {
    const apiUrl = process.env?.APP_API_REST_SERVER_URL;

    commit('setLoading', true)
    const cityFiasId = rootState?.core?.city?.cityFiasId
    if (!cityFiasId) {
      throw new Error('city is not define')
    }
    const filters = {
      city_fias_id: cityFiasId,
      search: payload.search !== 1 ? payload.search : null,
      position: payload?.position?.key,
      employment: payload?.employment?.key,
      metro: payload?.metro?.title,
      tag: payload?.tag,
      salary_from: +payload?.salaryFrom,
      salary_to: +payload?.salaryTo,
      limit: payload?.limit || 10,
      offset: payload?.offset || 0,
    }

    const resFilters = pickBy(filters)
    try {
      const res = await this.$axios.get(`${apiUrl}/vacancy/`, {
        params: resFilters,
      })
      if (res?.data) {
        commit('setList', camelize(res?.data?.data || []))
        commit('setListMeta', res?.data?.meta)
        return Promise.resolve(res.data?.data)
      }
      return Promise.reject(new Error("can't load vacancies"))
    } catch {
      return Promise.reject(new Error("can't load vacancies"))
    } finally {
      commit('setLoading', false)
    }
  },
  // Mobile
  loadListMobile({ commit, rootState }, payload) {
    commit('setLoading', true)
    const cityConfig = rootState?.meta?.pageContent?.filters?.cityFiasId
    const requestVariables = {
      filter: {
        cityFiasId:
          cityConfig ||
          payload?.cityFiasId ||
          '0c5b2444-70a0-4932-980c-b4dc0d3f02b5', // Moscow
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    }
    if (payload) {
      if (payload.search && payload?.search?.length !== 1) {
        requestVariables.filter.search = payload.search
      }
      if (payload.position?.key) {
        requestVariables.filter.position = payload.position.key
      }
      if (payload.employment?.key) {
        requestVariables.filter.employment = payload.employment.key
      }
      if (payload.metro?.title) {
        requestVariables.filter.metro = payload.metro.title
      }
      if (payload.tag) {
        requestVariables.filter.tag = payload.tag
      }
      if (payload.limit) {
        requestVariables.pagination.limit = payload.limit
      }
      if (payload.offset) {
        requestVariables.pagination.offset = payload.offset
      }
      if (payload.salaryFrom) {
        set(
          requestVariables,
          'filter.salary.from',
          parseInt?.(payload?.salaryFrom || 0, 10)
        )
      }
      if (payload.salaryTo) {
        set(
          requestVariables,
          'filter.salary.to',
          parseInt?.(payload?.salaryTo || 0, 10)
        )
      }
    }
    return this.$graphql.api_v2
      .request(LOAD_VACANCIES, requestVariables)
      .then((res) => {
        if (res?.publicVacancyList) {
          commit('setList', res.publicVacancyList?.data)
          commit('setListMeta', res.publicVacancyList?.meta)
          return Promise.resolve(res.publicVacancyList?.data)
        }
        return Promise.reject(new Error("can't load vacancies"))
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
}
