//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { get } from 'lodash'
import AnalyticCore from '~/services/analytics'

export default {
  name: 'BaseNav',
  props: {
    links: {
      type: Array || Function,
      default: [{ key: 'key', title: '', link: './#' }],
    },
    linksLight: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '',
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuIsClosed: true,
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
    classesLi() {
      return {
        'links-light': this.linksLight,
        'links-dark': !this.linksLight,
      }
    },
    classesUl() {
      return {
        [`${this.height}`]: this.height,
        'w-full': !this.modal,
      }
    },
    classesModal() {
      return {
        hidden: this.menuIsClosed,
        absolute: !this.menuIsClosed,
      }
    },
    linksFooter() {
      return this.links
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.menuIsClosed = true
      },
    },
  },
  methods: {
    onMenu() {
      this.menuIsClosed = !this.menuIsClosed
    },
    sendAnalytic(link) {
      if (!this.linksLight) {
        let eventContext = ''
        if (link.title === 'Вакансии') {
          eventContext = 'vacancy'
        }
        if (link.title === 'Обучение') {
          eventContext = 'training'
        }
        if (link.title === 'Акции') {
          eventContext = 'stocks'
        }
        if (link.title === 'Партнёрам') {
          eventContext = 'partners'
        }
        if (link.title === 'О нас') {
          eventContext = 'us'
        }
        if (link.title === 'Работа у нас') {
          eventContext = 'vacancy'
        }
        if (link.title === 'Контакты') {
          eventContext = 'contacts'
        }
        if (this.$Analytics) {
          this.$Analytics.push({
            ...AnalyticCore.EVENT_BASE,
            event: `footer_link_${eventContext}`,
            city: this.localCity?.city || '',
            utm_campaign: get(this.$route.query, 'utm_campaign', ''),
            utm_content: get(this.$route.query, 'utm_content', ''),
            utm_medium: get(this.$route.query, 'utm_medium', ''),
            utm_source: get(this.$route.query, 'utm_source', ''),
            utm_term: get(this.$route.query, 'utm_term', ''),
            path: window.location.href,
            eventAction: AnalyticCore.EVENT_ACTION.click,
            eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
            eventLabel: 'link',
            eventContext: eventContext || '',
            eventContent: null,
            eventLocation: 'basement',
          })
        }
      }
    },
  },
}
