// let city = ''
let coords = null
let cityFiasId = null
if (process.client) {
  // city = localStorage.getItem('user:city')
  coords = localStorage.getItem('user:coords')
    ? JSON.parse(localStorage.getItem('user:coords'))
    : null
  cityFiasId = localStorage.getItem('user:cityFiasId')
    ? JSON.parse(localStorage.getItem('user:cityFiasId'))
    : null
}

export const state = () => ({
  city: '',
  phone: '800 600-69-67',
  coords: coords || null,
  cityFiasId: cityFiasId || null,
})

export default state
