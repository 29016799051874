import { isIPAddress } from '~/common/utils/regex'
import { LOAD_CITIES_WITH_VACANCIES } from '~/services/api/graphql/query/loadCitiesWithVacancies'

export default {
  async load(ctx, { $config, req, isDev, error }) {
    // * parse runtime config
    // prepare config
    const configPayload = {}
    Object.entries($config).forEach(([key, value]) => {
      if (typeof value === 'string') {
        configPayload[key] = value
      }
    })
    configPayload.isDev = isDev
    if (configPayload?.rootDomain?.indexOf(':') >= 0) {
      const [domain] = configPayload.rootDomain.split(':')
      configPayload.cookieDomain = domain
    } else {
      configPayload.cookieDomain = configPayload.rootDomain
    }
    // save config
    ctx.commit('setConfig', configPayload)

    // * parse subdomain
    ctx.commit('setProtocol', req.headers['x-forwarded-proto'])

    const host = req.headers.host
    if (!isIPAddress(host)) {
      const domainParts = host.replace(configPayload?.rootDomain, '')

      const [part] = domainParts.split('.')
      const subdomain = part ? part.replace(/[^a-zа-яё]/gi, '') : ''
      ctx.commit('setSubDomain', subdomain)

      try {
        const res = await this.$graphql.api_server.request(
          LOAD_CITIES_WITH_VACANCIES
        )
        if (res?.dictionaries?.citiesWithVacancies) {
          const temp = res?.dictionaries?.citiesWithVacancies || []
          const sortList = temp.sort((a, b) => b?.count - a?.count)
          const city = sortList.find((item) => item.domain === subdomain)
          //* Проверяем что не Москва
          if (
            city &&
            city?.cityFiasId !== '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'
          ) {
            ctx.commit('setCity', city)
          }
          const apiUrl = process.env?.APP_API_REST_SERVER_URL;

          if (!city?.city || city?.city === 'Москва') {
            const cityFiasId = city?.city || 'Москва'
            const listRes = await this.$axios.get(
              `${apiUrl}/vacancy/?city=${encodeURIComponent(
                cityFiasId
              )}&limit=10&offset=0`
            )
            if (listRes?.data?.data && listRes?.data?.meta) {
              await ctx.commit('vacancies/setList', listRes?.data?.data, {
                root: true,
              })
              await ctx.commit('vacancies/setListMeta', listRes?.data?.meta, {
                root: true,
              })
            }
          } else {
            const cityFiasId =
              city?.cityFiasId || '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'
            const listRes = await this.$axios.get(
              `${apiUrl}/vacancy/?city_fias_id=${cityFiasId}&limit=10&offset=0`
            )
            if (listRes?.data?.data && listRes?.data?.meta) {
              await ctx.commit('vacancies/setList', listRes?.data?.data, {
                root: true,
              })
              await ctx.commit('vacancies/setListMeta', listRes?.data?.meta, {
                root: true,
              })
            }
          }
          if (!city && subdomain && subdomain !== 'm') {
            error({ status: 404, message: 'Page not found' })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    return Promise.resolve()
  },
  updateFiasId({ commit }, payload) {
    commit('setCity', payload)
  },
}
