export default function ({ $axios, redirect }) {
  $axios.onRequest((config) => {
    config.metadata = {
      startTime: new Date(),
    }
    return config
  })

  $axios.onResponse((response) => {
    console.log(`[${response?.status}] ${response?.request?.path}`)
    const duration = new Date() - response?.config?.metadata?.startTime
    console.log(`Request to ${response?.config?.url} took ${duration} ms`)
    return response
  })

  $axios.onError((err) => {
    console.log(
      `[${err.response && err.response.status}] ${
        err.response && err.response?.request?.path
      }`
    )
    console.log(err.response && err.response?.data)
  })
}
