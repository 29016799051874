import { setCookie } from '~/common/helpers/cookie'

export default {
  getUserCity({ rootState, commit }) {
    if (process.client) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: 'GET',
          url: rootState.core.config.geoCityUrl,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + rootState.core.config.geoKey,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              const location = res.data.location
              if (location) {
                const city = location?.data?.city
                const coords = {
                  lat: location?.data?.geo_lat,
                  lng: location?.data?.geo_lon,
                }
                const cityFiasId = location?.data?.fias_id
                commit('setUserLocation', { coords, cityFiasId })
                resolve(city)
              }
              reject(new Error('Не удалось определить'))
            }
            reject(new Error('Не удалось определить'))
          })
          .catch((err) => {
            console.error(err)
            reject(new Error('Не удалось определить'))
          })
      })
    }
  },
  confirmCity({ rootState, commit }, { city, coords, cityFiasId }) {
    if (process.client) {
      if (coords) {
        setCookie('rabotut:user:coords', JSON.stringify(coords), {
          domain: rootState.core.config.cookieDomain,
        })
        commit('setUserLocation', { coords })
      }
      if (city) {
        setCookie('rabotut:user:city', city, {
          domain: rootState.core.config.cookieDomain,
        })
        commit('setUserLocation', { city })
      }
      if (cityFiasId) {
        setCookie('rabotut:user:cityFiasId', cityFiasId, {
          domain: rootState.core.config.cookieDomain,
        })
        commit('setUserLocation', { cityFiasId })
      }
    }
  },
  getPhoneCity({ rootState, commit }) {
    if (process.client) {
      const city = rootState?.core?.city?.city
      let phone = '800 600-69-67'
      if (city === 'Рязань') {
        phone = '800 511-54-84'
      }
      if (city === 'Иваново') {
        phone = '800 511-54-84'
      }
      commit('setPhoneCity', { phone, city })
    }
  },
  searchCities({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios({
        method: 'POST',
        url: rootState.core.config.geoAddressUrl,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + rootState.core.config.geoKey,
        },
        data: JSON.stringify({
          query: payload,
          count: 10,
          from_bound: { value: 'city' },
          to_bound: { value: 'city' },
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            const cities = res.data.suggestions.map((item) => ({
              value: item.value,
              city: item.data.city,
              coords: {
                lat: item.data.geo_lat,
                lng: item.data.geo_lon,
              },
              cityFiasId: item?.data?.fias_id,
            }))
            resolve(cities)
          }
          resolve([])
        })
        .catch((err) => {
          console.error(err)
          reject(new Error('Не удалось опредилить'))
        })
    })
  },
}
