import { gql } from 'nuxt-graphql-request'

export const CONTENT_PAGE_BY_URL = gql`
  query contentPageByUrl($url: String!) {
    contentPageByUrl(url: $url) {
      id
      createdAt
      updatedAt
      deletedAt
      title
      ogTitle
      photoUrl
      description
      ogDescription
      keywords
      url
      createdBy
      updatedBy
    }
  }
`

export default CONTENT_PAGE_BY_URL
