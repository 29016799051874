import { VACANCY_COUNT } from '~/services/api/graphql/query/vacancyCount'
import { LOAD_CITIES_WITH_VACANCIES } from '~/services/api/graphql/query/loadCitiesWithVacancies'
const DICTIONARIES = process.env.APP_DICTIONARIES_URL;
export default {
  async loadDictionaries({ dispatch, commit }) {
    const requestArr = [
      dispatch('loadPositions'),
      dispatch('loadEmployments'),
      dispatch('loadTags'),
    ]
    await Promise.all(requestArr)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((e) => {
        console.error(e)
        return Promise.reject(e)
      })
      .finally(() => {
        commit('setLoaded', true)
      })
  },
  loadPositions({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${DICTIONARIES}/dict_position.json`)
        .then((res) => {
          if (res?.data) {
            commit('setPositions', res.data)
            resolve(res.data)
          }
        })
        .catch((e) => {
          console.error(e)
          return reject(e)
        })
    })
  },
  loadEmployments({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${DICTIONARIES}/dict_employment.json`)
        .then((res) => {
          if (res?.data) {
            commit('setEmployments', res.data)
            resolve(res.data)
          }
        })
        .catch((e) => {
          console.error(e)
          return reject(e)
        })
    })
  },
  loadTags({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${DICTIONARIES}/dict_vacancy_tag.json`)
        .then((res) => {
          if (res?.data) {
            commit('setVacancyTags', res.data)
            resolve(res.data)
          }
        })
        .catch((e) => {
          console.error(e)
          return reject(e)
        })
    })
  },
  async getInfo({ commit }) {
    // TODO: get cv counters
    try {
      const res = await this.$graphql.api_server.request(VACANCY_COUNT)
      if (res?.vacancyCount) {
        commit('setVacancyCount', res?.vacancyCount?.count || null)
        return Promise.resolve()
      }
      return Promise.reject(new Error("can't load vacancyCount"))
    } catch (e) {
      console.error(e)
      return Promise.reject(e)
    }
  },
  loadCitiesWithVacancies({ commit }, context) {
    commit('setCityLoading', true)
    return new Promise((resolve, reject) => {
      this.$graphql.api_v2
        .request(LOAD_CITIES_WITH_VACANCIES)
        .then((res) => {
          if (res?.dictionaries?.citiesWithVacancies) {
            const temp = res?.dictionaries?.citiesWithVacancies || []
            const options = temp.map((item) => ({
              city: item.city,
              value: item.city,
              coords: {
                lat: item?.location?.lat,
                lng: item?.location?.lng,
              },
              cityFiasId: item?.cityFiasId,
              domain: item?.domain,
            }))
            commit('setCitiesWithVacancies', options)
            resolve(res.dictionaries.citiesWithVacancies || [])
          }
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          commit('setCityLoading', false)
        })
    })
  },
}
