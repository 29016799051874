//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
import LinkToAlert from '~/components/UI/Cards/LinkToAlert'
import tildaContentMixin from '~/common/mixins/TildaContentMixin'

export default {
  name: 'MainMobileLayout',
  components: {
    LinkToAlert,
  },
  mixins: [tildaContentMixin],
  scrollToTop: true,
  transition: {
    name: 'fade',
    mode: 'out-in',
  },
  head() {
    return {
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    }
  },
  computed: {
    ...mapState('dictionary', ['citiesWithVacancies']),
  },
  mounted() {
    this.setCityCore()
  },
  methods: {
    ...mapMutations('core', ['setCity']),
    async setCityCore() {
      const city = this.citiesWithVacancies?.find(
        (item) => item?.cityFiasId === this.$route?.params?.id
      )?.city
      const payload = {
        cityFiasId: city
          ? this.$route?.params?.id
          : '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
        city: city || 'Москва',
        domain: 'm',
      }
      await this.setCity(payload)
      // console.log(this.$route)
      // if(!city) {
      //   this.$router.push('/city/0c5b2444-70a0-4932-980c-b4dc0d3f02b5/vacancies')
      // }
    },
  },
}
