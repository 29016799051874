export const state = () => ({
  positions: [],
  typeEmployment: [],
  isLoaded: false,
  vacancyCount: null,
  tags: [],
  citiesWithVacancies: [
    {
      city: 'Москва',
      coords: {
        lat: '55.75396',
        lng: '37.620393',
      },
      value: 'Москва',
      cityFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    },
    {
      city: 'Санкт-Петербург',
      value: 'Санкт-Петербург',
      coords: {
        lat: '59.939084',
        lng: '30.315879',
      },
      cityFiasId: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    },
    {
      city: 'Казань',
      value: 'Казань',
      coords: {
        lat: '55.794438',
        lng: '49.111451',
      },
      cityFiasId: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
    },
    {
      city: 'Нижний Новгород',
      value: 'Нижний Новгород',
      coords: {
        lat: '56.324133',
        lng: '44.005299',
      },
      cityFiasId: '555e7d61-d9a7-4ba6-9770-6caa8198c483',
    },
    {
      city: 'Челябинск',
      value: 'Челябинск',
      coords: {
        lat: '55.1602624',
        lng: '61.4008078',
      },
      cityFiasId: 'a376e68d-724a-4472-be7c-891bdb09ae32',
    },
    {
      city: 'Самара',
      value: 'Самара',
      coords: {
        lat: '53.195096',
        lng: '50.106868',
      },
      cityFiasId: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b',
    },
    {
      city: 'Ростов-на-Дону',
      value: 'Ростов-на-Дону',
      coords: {
        lat: '47.222531',
        lng: '39.718705',
      },
      cityFiasId: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5',
    },
    {
      city: 'Екатеринбург',
      value: 'Екатеринбург',
      coords: {
        lat: '56.838607',
        lng: '60.605514',
      },
      cityFiasId: '2763c110-cb8b-416a-9dac-ad28a55b4402',
    },
    {
      city: 'Новосибирск',
      value: 'Новосибирск',
      coords: {
        lat: '55.028141',
        lng: '82.921117',
      },
      cityFiasId: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
    },
    {
      city: 'Омск',
      value: 'Омск',
      coords: {
        lat: '54.9848566',
        lng: '73.3674517',
      },
      cityFiasId: '140e31da-27bf-4519-9ea0-6185d681d44e',
    },
  ],
  isCityLoading: false,
})

export default state
