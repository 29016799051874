import { render, staticRenderFns } from "./CityWidget.vue?vue&type=template&id=3dc278da&scoped=true&"
import script from "./CityWidget.vue?vue&type=script&lang=js&"
export * from "./CityWidget.vue?vue&type=script&lang=js&"
import style0 from "./CityWidget.vue?vue&type=style&index=0&id=3dc278da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc278da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CityConfirmPopup: require('/builds/rabotut/ui-web/project/components/City/CityConfirmPopup.vue').default,CityPicker: require('/builds/rabotut/ui-web/project/components/City/CityPicker.vue').default})
