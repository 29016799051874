import Vue from 'vue'
import { get } from 'lodash'

Vue.filter('toPlural', (value, one, two, five, isShowValue = true) => {
  let n = Math.abs(value)
  n %= 100

  const quantity = isShowValue ? value : ''

  if (n >= 5 && n <= 20) {
    return `${quantity} ${five}`
  }

  n %= 10
  if (n === 1) {
    return `${quantity} ${one}`
  }

  if (n >= 2 && n <= 4) {
    return `${quantity} ${two}`
  }
  if (!value) {
    return ''
  }

  return `${quantity} ${five}`
})

Vue.filter('fio', (item) => {
  const last = get(item, ['lastName'], '')
  const first = get(item, ['firstName'], '')
  const middle = get(item, ['middleName'], '')
  return `${last || ''} ${first || ''} ${middle || ''}`
})

Vue.filter('lowercase', (value) => {
  if (!value) return ''
  return value.toString().toLowerCase()
})

Vue.filter('truncate', (text, length = 300, clamp = '...') => {
  const limit = Math.max(0, length - 3)
  if (text.length < limit) return text
  return text.slice(0, limit) + clamp
})

Vue.filter('currency', (value, locale = 'ru-RU') => {
  if (!value) return ''

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  return currencyFormatter.format(value)
})

Vue.filter('number', (value, locale = 'ru-RU') => {
  if (!value) return ''

  const numberFormatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  return numberFormatter.format(value)
})
