import { mapState } from 'vuex'

export default {
  data() {
    return {
      content: {},
    }
  },
  computed: {
    ...mapState('meta', {
      pageContent: 'pageContent',
    }),
  },
  methods: {
    uniqueScriptResolver(pre, post) {
      const res = pre
      post.map((item) => {
        if (!res.includes(item)) {
          res.push(item)
        }
        return item
      })
      return res
    },
    renderContent() {
      const content = this.content
      if (content.type === 'StaticPageDBModel' && content.head) {
        const scriptRegex =
          /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
        const arrScriptHead = content.head.match(scriptRegex)
        const head = document.head

        arrScriptHead.forEach((scriptString) => {
          head.insertAdjacentHTML('beforeend', scriptString)
        })
        //
        // const scriptElement = document.createElement('script')
        //
        // // scriptElement.textContent = `
        // //       function t_onReady(func) {
        // //         if (document.readyState != 'loading') {
        // //           func();
        // //         } else {
        // //           document.addEventListener('DOMContentLoaded', func);
        // //         }
        // //       }
        // //
        // //       function t_onFuncLoad(funcName, okFunc, time) {
        // //         if (typeof window[funcName] === 'function') {
        // //           okFunc();
        // //         } else {
        // //           setTimeout(function () {
        // //             t_onFuncLoad(funcName, okFunc, time);
        // //           }, (time || 100));
        // //         }
        // //       }
        // //     `
        // document.head.prepend(scriptElement)
      }
      let commonCss = []
      let commonJs = []
      if (content.type === 'DynamicPageDBModel') {
        const preContentJs = content?.script?.pre?.js || []
        const preContentCss = content?.script?.pre?.css || []
        const postContentJs = content?.script?.post?.js || []
        const postContentCss = content?.script?.post?.css || []
        commonCss = this.uniqueScriptResolver(preContentCss, postContentCss)
        commonJs = this.uniqueScriptResolver(preContentJs, postContentJs)
      }
      if (
        content.type === 'SystemPageDBModel' ||
        content.type === 'StaticPageDBModel'
      ) {
        commonCss = content.css
        commonJs = content.js
      }

      if (commonJs?.length) {
        const scripts = commonJs.filter(
          (item) =>
            item !== 'https://static.tildacdn.com/js/tilda-polyfill-1.0.min.js'
        )
        scripts.forEach((script) => {
          const el = document.createElement('script')
          el.setAttribute('src', script)
          const targetBlock = document.getElementById('tilda-content')
          if (targetBlock) {
            targetBlock.appendChild(el)
          }
        })
      }
      const css = commonCss.filter(
        (item) =>
          item !== 'https://static.tildacdn.com/css/tilda-grid-3.0.min.css'
      )
      css.forEach((css) => {
        const el = document.createElement('link')
        el.setAttribute('rel', 'stylesheet')
        el.setAttribute('href', css)
        const targetBlock = document.getElementById('tilda-content')
        if (targetBlock) {
          targetBlock.appendChild(el)
        }
      })
    },
  },
  mounted() {
    this.content = this.pageContent
    this.renderContent()
  },
}
