export const state = () => ({
  list: [],
  listMeta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
  hotList: [],
  mapData: null,
  current: null,
  loading: false,
})

export default state
