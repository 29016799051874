import CONTENT_PAGE_BY_URL from '~/services/api/graphql/query/contentPageByUrl'
import { camelize } from '~/common/helpers/keysConverter'

export default {
  getPageMeta({ commit, rootState }, payload) {
    const variables = { url: payload }
    return new Promise((resolve, reject) => {
      this.$graphql.api_v2
        .request(CONTENT_PAGE_BY_URL, variables)
        .then((res) => {
          const pageMeta = res.contentPageByUrl
          const resPageMeta = {
            title: pageMeta.title,
            meta: [
              {
                hid: 'og:title',
                name: 'og:title',
                content: pageMeta.ogTitle,
              },
              {
                hid: 'description',
                name: 'description',
                content: pageMeta.description,
              },
              {
                hid: 'og:description',
                name: 'og:description',
                content: pageMeta.ogDescription,
              },
              {
                hid: 'keywords',
                name: 'keywords',
                content: pageMeta.keywords,
              },
            ],
          }
          commit('fillPageMeta', resPageMeta)
        })
        .catch((e) => {
          console.error(e)
          commit('fillPageMeta', {})
          reject(e)
        })
    })
  },
  async getPageContent({ commit, dispatch }, payload) {
    const { route, $config, redirect } = payload
    const apiUrl = !process.client
      ? $config.apiContentServerUrl
      : $config.apiContentUrl
    const currentRoute = route.path
    const clearRoute =
      currentRoute !== '/' ? currentRoute?.replace(/\/+$/, '') : currentRoute
    const pageUrl = `${$config?.appUrl}${clearRoute}`
    const requestUrl = `${apiUrl}/page/info?url=${pageUrl}`
    try {
      if (requestUrl) {
        const res = await this.$axios.post(requestUrl)
        if (res.data) {
          const data = camelize(res.data)
          if (data?.type === 'RedirectPageDBModel') {
            const targetURL = data?.targetUrl || '/'
            const code = data?.code || 302
            redirect(code, targetURL)
          }
          const pageMeta = data?.meta
          const preparedMetaData = {
            meta: [
              {
                hid: 'og:title',
                name: 'og:title',
                content: pageMeta?.ogTitle || '',
              },
              {
                hid: 'description',
                name: 'description',
                content: pageMeta?.description || '',
              },
              {
                hid: 'og:description',
                name: 'og:description',
                content: pageMeta?.ogDescription || '',
              },
              {
                hid: 'keywords',
                name: 'keywords',
                content: pageMeta?.keywords || '',
              },
            ],
            title: pageMeta?.title || '',
          }
          commit('setCurrentPageMeta', preparedMetaData)
          commit('setPageContent', data)
          if (data?.type === 'DynamicPageDBModel') {
            const query = route.query
            const urlFilters = {
              tag: query?.tag || '',
              salaryTo: query?.salaryTo || '',
              salaryFrom: query?.salaryFrom || '',
              position: query?.position || '',
              search: query?.search || '',
              employment: query?.employment || '',
              metro: query?.metro || '',
            }
            const payload = {
              search: data?.filters?.search || urlFilters?.search || '',
              position: {
                key: data?.filters?.position || urlFilters?.position || '',
              },
              employment: {
                key: data?.filters?.employment || urlFilters?.employment || '',
              },
              metro: {
                title: data?.filters?.metro || urlFilters?.metro || '',
              },
              tag: data?.filters?.tag || urlFilters?.tag || '',
              salaryFrom:
                data?.filters?.salary?.from || urlFilters?.salaryFrom || '',
              salaryTo: data?.filters?.salary?.to || urlFilters?.salaryTo || '',
            }
            if (process.server) {
              await dispatch('vacancies/loadServerList', payload, {
                root: true,
              })
            }
            if (process.client) {
              await dispatch('vacancies/loadList', payload, { root: true })
            }
          }
          return Promise.resolve(data)
        }
        console.error('cant load page content')
      } else {
        console.error('requestUrl is not define')
      }
    } catch (error) {
      console.error(error)
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log('Error', error.message)
      }
      console.log(error.config)
      return Promise.reject(error)
    }
  },
  // Mobile
  async getPageContentMobile({ commit, dispatch, rootState }, payload) {
    const { route, $config, redirect } = payload
    const apiUrl = !process.client
      ? $config.apiContentServerUrl
      : $config.apiContentUrl
    let currentRoute = route.path
    let pageUrl = `${$config?.appUrl}${currentRoute}`
    const cityFiasId =
      route?.params?.id || '0c5b2444-70a0-4932-980c-b4dc0d3f02b5' // Moscow
    if (rootState.core.subDomain === 'm') {
      if (currentRoute.replace(`city/${cityFiasId}`, '').length > 1) {
        currentRoute = currentRoute.replace(`city/${cityFiasId}/`, '')
      } else {
        currentRoute = currentRoute.replace(`city/${cityFiasId}`, '')
      }
      pageUrl = `${$config?.appUrl}${currentRoute}`
    }
    const requestUrl = `${apiUrl}/page/info?url=${pageUrl}`
    try {
      if (requestUrl) {
        const res = await this.$axios.post(requestUrl)
        if (res.data) {
          const data = camelize(res.data)
          if (data?.type === 'RedirectPageDBModel') {
            const targetURL = data?.targetUrl || '/'
            const code = data?.code || 302
            redirect(code, targetURL)
          }
          commit('setPageContent', data)
          if (data?.type === 'DynamicPageDBModel') {
            const query = route.query
            const urlFilters = {
              tag: query?.tag || '',
              salaryTo: query?.salaryTo || '',
              salaryFrom: query?.salaryFrom || '',
              position: query?.position || '',
              search: query?.search || '',
              employment: query?.employment || '',
              metro: query?.metro || '',
            }
            const payload = {
              search: data?.filters?.search || urlFilters?.search || '',
              position: {
                key: data?.filters?.position || urlFilters?.position || '',
              },
              employment: {
                key: data?.filters?.employment || urlFilters?.employment || '',
              },
              metro: {
                title: data?.filters?.metro || urlFilters?.metro || '',
              },
              tag: data?.filters?.tag || urlFilters?.tag || '',
              salaryFrom:
                data?.filters?.salary?.from || urlFilters?.salaryFrom || '',
              salaryTo: data?.filters?.salary?.to || urlFilters?.salaryTo || '',
              cityFiasId,
            }
            if (process.client) {
              await dispatch('vacancies/loadListMobile', payload, {
                root: true,
              })
            }
          }
          return Promise.resolve(data)
        }
        console.error('cant load page content')
      } else {
        console.error('requestUrl is not define')
      }
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  },
}
