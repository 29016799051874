//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseButton',
  props: {
    htmlType: {
      type: String,
      default: 'button',
    },
    label: {
      type: String,
      default: 'Button',
    },
    type: {
      type: String,
      default: 'primary',
      validator: (val) =>
        ['primary', 'secondary', 'standard', 'outlined', 'red'].includes(val),
    },
    size: {
      type: String,
      default: 'standard',
    },
    padding: {
      type: String,
      default: 'p-2',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        button: true,
        'rounded-small': !this.rounded,
        'rounded-full': this.rounded,
        'button--primary': this.type === 'primary',
        'button--secondary': this.type === 'secondary',
        'button--standard': this.type === 'standard',
        'button--outlined': this.type === 'outlined',
        'button--red': this.type === 'red',
        // [`button--size-${this.size}`]: true,
        'button--disabled': this.disabled,
        [`${this.padding}`]: this.padding,
      }
    },
  },
}
