export default {
  setUserLocation(state, { city, coords, cityFiasId }) {
    if (city) {
      state.city = city
    }
    if (coords) {
      state.coords = coords
    }
    if (cityFiasId) {
      state.cityFiasId = cityFiasId
    }
  },
  setPhoneCity(state, { phone, city }) {
    if (city) {
      state.phone = phone
    }
  },
  setCityMounted(state, payload) {
    if (payload) {
      state.city = payload
    }
  },
}
