//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import CityPicker from '~/components/City/CityPicker.vue'
import CityConfirmPopup from '~/components/City/CityConfirmPopup.vue'
import { LOG_LEVEL, Logger } from '~/services/logger/log'

//* const MOSCOW_CITY_DOMAIN = {
//   moscow: {
//     domain: 'moscow',
//     city: 'Москва',
//     coords: {
//       lat: 55.75222,
//       lng: 37.61556,
//     },
//     cityFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
//   },
// }

export default {
  name: 'CityWidget',
  components: {
    CityConfirmPopup,
    CityPicker,
  },
  data() {
    return {
      showPicker: false,
      city: 'Определяем...',
      isCity: false,
      isCityConfirmed: true,
      error: '',
    }
  },
  computed: {
    ...mapState('core', {
      subDomain: 'subDomain',
      config: 'config',
      currentCity: 'city',
    }),
    ...mapState('dictionary', ['citiesWithVacancies']),
    isDev() {
      return this.config?.isDev
    },
  },
  mounted() {
    this.getPhoneCity()
  },
  methods: {
    ...mapActions('user', ['getUserCity', 'searchCities', 'getPhoneCity']),
    ...mapActions('core', ['updateFiasId']),
    openPicker() {
      this.showPicker = true
    },
    closePicker() {
      this.showPicker = false
      this.error = ''
    },

    onConfirm(e) {
      const domain = e?.domain
      const currentDomain = this.currentCity?.domain || ''
      const currentCityFiasId = this.currentCity?.cityFiasId
      const availableListDomain = this.citiesWithVacancies
        .map((item) => item.domain)
        .filter(Boolean)
      const protocol = this.isDev ? 'http' : 'https'
      //* проверка на соответствие текущего домена и выбранного
      if (this.currentCity?.domain !== domain) {
        //* кейс выбора Москвы
        if (domain === 'moskva') {
          const targetUrl = new URL(
            this.$route.fullPath,
            `${protocol}://${this.config.rootDomain}`
          )
          window.location.replace(targetUrl.toString())
          return
        }
        //* кейс выбора города с существующим доменом
        if (domain && availableListDomain.includes(domain)) {
          this.updateFiasId(e)
          const targetUrl = new URL(
            this.$route.fullPath,
            `${protocol}://${domain}.${this.config.rootDomain}`
          )
          window.location.replace(targetUrl.toString())
        }
        //* кейс выбора города без вакансий из dadata
        if (!domain) {
          //* проверка на совпадение cityFiasId в списке городов с вакансиями
          const cityFiasId = e?.cityFiasId
          const city = this.citiesWithVacancies.find(
            (item) => item.cityFiasId === cityFiasId
          )
          //* найденный город является текущим выбранным
          if (city?.domain && city.domain === currentDomain) {
            if (currentCityFiasId !== e?.cityFiasId) {
              this.updateFiasId(e)
              this.closePicker()
              return
            } else {
              this.closePicker()
              return
            }
          }
          //* найденный город есть в списке городов с вакансиями
          if (city?.domain && city.domain !== currentDomain) {
            //* найденный город не Москва
            if (city?.domain && city?.domain !== 'moskva') {
              const targetUrl = new URL(
                this.$route.fullPath,
                `${protocol}://${city.domain}.${this.config.rootDomain}`
              )
              window.location.replace(targetUrl.toString())
              this.updateFiasId(e)
            }
            //* найденный город Москва
            if (city.domain === 'moskva') {
              if (currentCityFiasId !== cityFiasId) {
                this.updateFiasId(e)
                this.closePicker()
                return
              } else {
                const targetUrl = new URL(
                  this.$route.fullPath,
                  `${protocol}://${this.config.rootDomain}`
                )
                window.location.replace(targetUrl.toString())
              }
            }
          }
          //* найденного города нет в списке городов с вакансиями
          if (!city?.domain) {
            const log = {
              level: LOG_LEVEL.ERROR,
              message: `По данному запросу вакансии не найдены. ${e?.city} - ${e?.cityFiasId}`,
              component: 'CityWidget',
            }
            this.error = 'По данному запросу вакансии не найдены.'
            const logger = Logger.get()
            logger.send(log)
          }
        }
      } else {
        if (currentCityFiasId !== e?.cityFiasId) {
          this.updateFiasId(e)
          this.closePicker()
          return
        }
        this.closePicker()
      }
    },
    clearErr() {
      this.error = ''
    },
  },
}
