export const Breadcrumbs = () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const EcoSystem = () => import('../../components/EcoSystem.vue' /* webpackChunkName: "components/eco-system" */).then(c => wrapFunctional(c.default || c))
export const FeedbackSuccessModal = () => import('../../components/FeedbackSuccessModal.vue' /* webpackChunkName: "components/feedback-success-modal" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HelpBanner = () => import('../../components/HelpBanner.vue' /* webpackChunkName: "components/help-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeForm = () => import('../../components/HomeForm.vue' /* webpackChunkName: "components/home-form" */).then(c => wrapFunctional(c.default || c))
export const MobileAppBanner = () => import('../../components/MobileAppBanner.vue' /* webpackChunkName: "components/mobile-app-banner" */).then(c => wrapFunctional(c.default || c))
export const ModalBanner = () => import('../../components/ModalBanner.vue' /* webpackChunkName: "components/modal-banner" */).then(c => wrapFunctional(c.default || c))
export const ModalComplain = () => import('../../components/ModalComplain.vue' /* webpackChunkName: "components/modal-complain" */).then(c => wrapFunctional(c.default || c))
export const ModalGiveaway = () => import('../../components/ModalGiveaway.vue' /* webpackChunkName: "components/modal-giveaway" */).then(c => wrapFunctional(c.default || c))
export const ModalRequest = () => import('../../components/ModalRequest.vue' /* webpackChunkName: "components/modal-request" */).then(c => wrapFunctional(c.default || c))
export const ModalRequestReserve = () => import('../../components/ModalRequestReserve.vue' /* webpackChunkName: "components/modal-request-reserve" */).then(c => wrapFunctional(c.default || c))
export const ModalResponse = () => import('../../components/ModalResponse.vue' /* webpackChunkName: "components/modal-response" */).then(c => wrapFunctional(c.default || c))
export const ModalResponseMobile = () => import('../../components/ModalResponseMobile.vue' /* webpackChunkName: "components/modal-response-mobile" */).then(c => wrapFunctional(c.default || c))
export const ModalTraining = () => import('../../components/ModalTraining.vue' /* webpackChunkName: "components/modal-training" */).then(c => wrapFunctional(c.default || c))
export const PartnersBanner = () => import('../../components/PartnersBanner.vue' /* webpackChunkName: "components/partners-banner" */).then(c => wrapFunctional(c.default || c))
export const ReplySuccessModal = () => import('../../components/ReplySuccessModal.vue' /* webpackChunkName: "components/reply-success-modal" */).then(c => wrapFunctional(c.default || c))
export const Salary = () => import('../../components/Salary.vue' /* webpackChunkName: "components/salary" */).then(c => wrapFunctional(c.default || c))
export const ThanksModal = () => import('../../components/ThanksModal.vue' /* webpackChunkName: "components/thanks-modal" */).then(c => wrapFunctional(c.default || c))
export const VacanciesBanner = () => import('../../components/VacanciesBanner.vue' /* webpackChunkName: "components/vacancies-banner" */).then(c => wrapFunctional(c.default || c))
export const VacancyFilters = () => import('../../components/VacancyFilters.vue' /* webpackChunkName: "components/vacancy-filters" */).then(c => wrapFunctional(c.default || c))
export const VacancyFiltersMobile = () => import('../../components/VacancyFiltersMobile.vue' /* webpackChunkName: "components/vacancy-filters-mobile" */).then(c => wrapFunctional(c.default || c))
export const VacancyTag = () => import('../../components/VacancyTag.vue' /* webpackChunkName: "components/vacancy-tag" */).then(c => wrapFunctional(c.default || c))
export const CityConfirmPopup = () => import('../../components/City/CityConfirmPopup.vue' /* webpackChunkName: "components/city-confirm-popup" */).then(c => wrapFunctional(c.default || c))
export const CityPicker = () => import('../../components/City/CityPicker.vue' /* webpackChunkName: "components/city-picker" */).then(c => wrapFunctional(c.default || c))
export const MapYandexMaps = () => import('../../components/Map/YandexMaps.vue' /* webpackChunkName: "components/map-yandex-maps" */).then(c => wrapFunctional(c.default || c))
export const PagePartsBenefits = () => import('../../components/PageParts/Benefits.vue' /* webpackChunkName: "components/page-parts-benefits" */).then(c => wrapFunctional(c.default || c))
export const PagePartsDynamicPageVacancies = () => import('../../components/PageParts/DynamicPageVacancies.vue' /* webpackChunkName: "components/page-parts-dynamic-page-vacancies" */).then(c => wrapFunctional(c.default || c))
export const PagePartsHotVacancies = () => import('../../components/PageParts/HotVacancies.vue' /* webpackChunkName: "components/page-parts-hot-vacancies" */).then(c => wrapFunctional(c.default || c))
export const PagePartsJumboSimple = () => import('../../components/PageParts/JumboSimple.vue' /* webpackChunkName: "components/page-parts-jumbo-simple" */).then(c => wrapFunctional(c.default || c))
export const PagePartsJumbotron = () => import('../../components/PageParts/Jumbotron.vue' /* webpackChunkName: "components/page-parts-jumbotron" */).then(c => wrapFunctional(c.default || c))
export const PagePartsMobileAppPanel = () => import('../../components/PageParts/MobileAppPanel.vue' /* webpackChunkName: "components/page-parts-mobile-app-panel" */).then(c => wrapFunctional(c.default || c))
export const PagePartsReviews = () => import('../../components/PageParts/Reviews.vue' /* webpackChunkName: "components/page-parts-reviews" */).then(c => wrapFunctional(c.default || c))
export const PagePartsVacancyFilterCards = () => import('../../components/PageParts/VacancyFilterCards.vue' /* webpackChunkName: "components/page-parts-vacancy-filter-cards" */).then(c => wrapFunctional(c.default || c))
export const UIBaseButton = () => import('../../components/UI/BaseButton.vue' /* webpackChunkName: "components/u-i-base-button" */).then(c => wrapFunctional(c.default || c))
export const UIBaseCollapse = () => import('../../components/UI/BaseCollapse.vue' /* webpackChunkName: "components/u-i-base-collapse" */).then(c => wrapFunctional(c.default || c))
export const UIBaseModal = () => import('../../components/UI/BaseModal.vue' /* webpackChunkName: "components/u-i-base-modal" */).then(c => wrapFunctional(c.default || c))
export const UIBaseNav = () => import('../../components/UI/BaseNav.vue' /* webpackChunkName: "components/u-i-base-nav" */).then(c => wrapFunctional(c.default || c))
export const UIBasePagination = () => import('../../components/UI/BasePagination.vue' /* webpackChunkName: "components/u-i-base-pagination" */).then(c => wrapFunctional(c.default || c))
export const UILoadSpinner = () => import('../../components/UI/LoadSpinner.vue' /* webpackChunkName: "components/u-i-load-spinner" */).then(c => wrapFunctional(c.default || c))
export const UILogoFrame = () => import('../../components/UI/LogoFrame.vue' /* webpackChunkName: "components/u-i-logo-frame" */).then(c => wrapFunctional(c.default || c))
export const UIMarkupRender = () => import('../../components/UI/MarkupRender.vue' /* webpackChunkName: "components/u-i-markup-render" */).then(c => wrapFunctional(c.default || c))
export const UIPersonaAvatar = () => import('../../components/UI/PersonaAvatar.vue' /* webpackChunkName: "components/u-i-persona-avatar" */).then(c => wrapFunctional(c.default || c))
export const UISalarySlider = () => import('../../components/UI/SalarySlider.vue' /* webpackChunkName: "components/u-i-salary-slider" */).then(c => wrapFunctional(c.default || c))
export const UISlider = () => import('../../components/UI/Slider.vue' /* webpackChunkName: "components/u-i-slider" */).then(c => wrapFunctional(c.default || c))
export const UITagsRadioButtons = () => import('../../components/UI/TagsRadioButtons.vue' /* webpackChunkName: "components/u-i-tags-radio-buttons" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCityWidget = () => import('../../components/widgets/CityWidget.vue' /* webpackChunkName: "components/widgets-city-widget" */).then(c => wrapFunctional(c.default || c))
export const WidgetsFilterCity = () => import('../../components/widgets/FilterCity.vue' /* webpackChunkName: "components/widgets-filter-city" */).then(c => wrapFunctional(c.default || c))
export const UICardsBaseCard = () => import('../../components/UI/Cards/BaseCard.vue' /* webpackChunkName: "components/u-i-cards-base-card" */).then(c => wrapFunctional(c.default || c))
export const UICardsBaseCardVac = () => import('../../components/UI/Cards/BaseCardVac.vue' /* webpackChunkName: "components/u-i-cards-base-card-vac" */).then(c => wrapFunctional(c.default || c))
export const UICardsBaseCardVacancy = () => import('../../components/UI/Cards/BaseCardVacancy.vue' /* webpackChunkName: "components/u-i-cards-base-card-vacancy" */).then(c => wrapFunctional(c.default || c))
export const UICardsCallPopUp = () => import('../../components/UI/Cards/CallPopUp.vue' /* webpackChunkName: "components/u-i-cards-call-pop-up" */).then(c => wrapFunctional(c.default || c))
export const UICardsCookieAlert = () => import('../../components/UI/Cards/CookieAlert.vue' /* webpackChunkName: "components/u-i-cards-cookie-alert" */).then(c => wrapFunctional(c.default || c))
export const UICardsLinkToAlert = () => import('../../components/UI/Cards/LinkToAlert.vue' /* webpackChunkName: "components/u-i-cards-link-to-alert" */).then(c => wrapFunctional(c.default || c))
export const UICardsPartnersCard = () => import('../../components/UI/Cards/PartnersCard.vue' /* webpackChunkName: "components/u-i-cards-partners-card" */).then(c => wrapFunctional(c.default || c))
export const UIFormBaseCheckbox = () => import('../../components/UI/form/BaseCheckbox.vue' /* webpackChunkName: "components/u-i-form-base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UIFormBaseInput = () => import('../../components/UI/form/BaseInput.vue' /* webpackChunkName: "components/u-i-form-base-input" */).then(c => wrapFunctional(c.default || c))
export const UIFormBaseSelect = () => import('../../components/UI/form/BaseSelect.vue' /* webpackChunkName: "components/u-i-form-base-select" */).then(c => wrapFunctional(c.default || c))
export const UIFormBaseTextarea = () => import('../../components/UI/form/BaseTextarea.vue' /* webpackChunkName: "components/u-i-form-base-textarea" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
