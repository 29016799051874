import Vue from 'vue'
import Vuex, { Store } from 'vuex'
// * modules
import core from '~/store/core/index.js'
import user from '~/store/modules/user'
import meta from '~/store/modules/meta'
import dictionary from '~/store/modules/dictionary'
import forms from '~/store/modules/forms'
import vacancies from '~/store/modules/vacancies'
import banners from '~/store/modules/banners'

Vue.use(Vuex)

export const store = () =>
  new Store({
    actions: {
      async nuxtServerInit(vuexContext, nuxtContext) {
        try {
          await Promise.all([
            vuexContext.dispatch('core/load', nuxtContext),
            vuexContext.dispatch('dictionary/loadDictionaries'),
            vuexContext.dispatch('dictionary/getInfo', nuxtContext),
          ])
        } catch (e) {
          console.error(e)
        }
      },
    },
    modules: {
      core,
      user,
      meta,
      dictionary,
      forms,
      vacancies,
      banners,
    },
  })

export default store
