//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '~/components/UI/BaseButton.vue'
export default {
  name: 'CookieAlert',
  components: { BaseButton },
  data() {
    return {
      showAlert: false,
      cookie: '',
    }
  },
  mounted() {
    const cookieAccepted = localStorage.getItem('accepted_cookie_policy')
    if (!cookieAccepted) {
      this.showAlert = true
    }
  },
  methods: {
    acceptPolicy() {
      if (process.client) {
        localStorage.setItem('accepted_cookie_policy', 'true')
        this.showAlert = false
      }
    },
  },
}
