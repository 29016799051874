//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { get } from 'lodash'
import AnalyticCore from '~/services/analytics'
import CityWidget from '~/components/widgets/CityWidget.vue'
import BaseNav from '~/components/UI/BaseNav.vue'
import BaseButton from '~/components/UI/BaseButton.vue'
import Modal from '~/components/UI/BaseModal.vue'
import ModalResponse from '~/components/ModalResponse.vue'

export default {
  name: 'CommonHeader',
  components: {
    BaseButton,
    CityWidget,
    BaseNav,
    Modal,
    ModalResponse,
  },
  props: {
    links: {
      type: Array || Function,
      default: [{ key: 'key', title: '', link: './#' }],
    },
  },
  data() {
    return {
      screenSize: null,
      isAvatarNav: false,
      showResponseModal: false,
      showConfirm: false,
      isModal: false,
    }
  },
  computed: {
    ...mapState('user', ['phone']),
    ...mapState('core', {
      localCity: 'city',
    }),
    hrefPhone() {
      return this.phone.replace(/[^0-9]/g, '')
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  methods: {
    onResize() {
      this.screenSize =
        document.documentElement.clientWidth > 1024 ? 'lg' : 'sm'
    },
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'cta_company_phone',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'link',
          eventContext: 'contacts',
          eventContent: null,
          eventLocation: 'main',
        })
      }
    },
    closeResponseModal() {
      this.showResponseModal = false
      this.closeModal()
    },
    openConfirm() {
      this.isModal = true
      this.showConfirm = true
    },
    closeConfirm() {
      this.showConfirm = false
      this.isModal = false
    },
    showModal() {
      this.isModal = true
      this.showResponseModal = true
    },
    closeModal() {
      this.isModal = false
      this.showConfirm = false
    },
  },
}
