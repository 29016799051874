export const isIPAddress = (matchStr) => {
  let testHost = matchStr
  // exclude port
  if (matchStr.includes(':')) {
    ;[testHost] = matchStr.split(':')
  }
  const regex =
    /^(?:25[0-5]|2[0-4]\d|[0-1]?\d{1,2})(?:\.(?:25[0-5]|2[0-4]\d|[0-1]?\d{1,2})){3}$/
  return regex.test(testHost)
}
