import * as Yup from 'yup'
import { yupToFormErrors } from '~/common/utils/validation.js'
import yupLocaleRu from '~/locale/yupLocaleRu'

Yup.setLocale(yupLocaleRu)

export const ResponseForm = Yup.object().shape({
  name: Yup.string().required('Заполните поле "Имя"'),
  phone: Yup.string()
    .required('Заполните поле "Номер телефона"')
    .length(16, 'Неверный формат ввода'),
})

export default {
  copyAttributes(attrs) {
    return {
      name: attrs.name || '',
      phone: attrs.phone || '',
    }
  },
  validate(form) {
    try {
      ResponseForm.validateSync(form, { abortEarly: false })
      return {}
    } catch (err) {
      return yupToFormErrors(err)
    }
  },
  async validateAt(field, form) {
    try {
      await ResponseForm.validateAt(field, form)
      return Promise.resolve({})
    } catch (err) {
      const errors = { [field]: err.errors[0] }
      return Promise.reject(errors)
    }
  },
}
