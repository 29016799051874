import { gql } from 'nuxt-graphql-request'

export const LOAD_VACANCIES = gql`
  query loadVacancies($filter: VacancyFilterType, $pagination: PaginationType) {
    publicVacancyList(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        shortDescription
        showContacts
        salary {
          from
          to
          gross
          currency
        }
        metro {
          distance
          line
          name
        }
        employer {
          id
          key
          logo
          name
        }
        contacts {
          fullName
          email
          phone
        }
        location {
          lat
          lng
        }
        address {
          value
          region
          regionType
          city
          cityType
          street
          streetType
          house
          houseType
          block
          blockType
          lat
          lng
          settlement
        }
        employment {
          id
          key
          name
        }
        manager {
          id
          firstName
          secondName
          email
          phone
          city
          role
          companyId
          departmentId
        }
        position {
          key
          name
          id
        }
        tag {
          name
          key
          color
          colorBg
        }
      }
      meta {
        limit
        offset
        total
      }
    }
  }
`
