import { gql } from 'nuxt-graphql-request'

export const LOAD_CITIES_WITH_VACANCIES = gql`
  query dictionaries {
    dictionaries {
      ... on Dictionaries {
        citiesWithVacancies {
          cityFiasId
          city
          count
          domain
          regionWithType
        }
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`
