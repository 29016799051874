import { gql } from 'nuxt-graphql-request'

export const CREATE_CLAIM = gql`
  mutation createClaim($form: ClaimInputType!) {
    createClaim(form: $form) {
      id
      category
      status
      position
      contacts {
        email
        fullName
        phone
      }
      source
    }
  }
`

export default CREATE_CLAIM
