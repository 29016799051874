//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorLayout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
