//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VModelMixin from '~/common/mixins/VModelMixin'

export default {
  name: 'BaseTextarea',
  mixins: [VModelMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    star: {
      type: Boolean,
      default: false,
    },
    inputLabel: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'info', // error, success
      validator: (value) => {
        return ['error', 'info', 'success'].includes(value) >= 0
      },
    },
    solo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [Number, String],
      default: 0,
    },
  },

  computed: {
    rootClasses() {
      const baseClass = 'control'
      const res = [baseClass]
      if (this.disabled) {
        res.push('disabled')
      }
      if (this.status) {
        res.push(`control--status-${this.status}`)
      }
      if (this.solo) {
        res.push('solo')
      }
      return res
    },
  },
}
