export default {
  setConfig(state, payload) {
    state.config = { ...payload }
  },
  setSubDomain(state, payload) {
    state.subDomain = payload || ''
  },
  setProtocol(state, payload) {
    state.protocol = payload || ''
  },
  setCity(state, payload) {
    state.city = payload
  },
}
