//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '~/components/UI/BaseButton.vue'
import Modal from '~/components/UI/BaseModal.vue'

export default {
  name: 'LinkToAlert',
  components: {
    BaseButton,
    Modal,
  },
  data() {
    return {
      isModal: false,
      timer: '',
    }
  },
  created() {
    if (process.client) {
      const linkAccepted = localStorage.getItem('link_to_telegram')
      if (!linkAccepted) {
        this.timer = setInterval(this.showModal, 40000)
      }
    }
  },
  methods: {
    showModal() {
      if (process.client) {
        this.isModal = true
        clearInterval(this.timer)
        localStorage.setItem('link_to_telegram', 'true')
      }
    },
    closeModal() {
      clearInterval(this.timer)
      this.isModal = false
    },
    toRedirect() {
      window.open('https://web.telegram.org/z/#216935562', '_blank')
      this.closeModal()
    },
  },
}
