// import Vue from "vue";
import { isEmpty } from 'lodash'

let instance

export class AnalyticCore {
  gtmKey = ''

  static EVENT_CATEGORY = {
    interaction: 'Interactions', // клики, открытия модальных окон и т.п.
    nonInteraction: 'nonInteraction', // При загрузке страницы
    conversion: 'Conversions', // отправка форм
    engagement: 'engagement', // Поиск
  }

  static EVENT_ACTION = {
    click: 'click',
    send: 'send',
    open: 'open',
  }

  static EVENT_BASE = {
    eventPosition: null,
    eventAction: 'click',
    utm_source: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  }

  constructor(gtmKey) {
    if (instance) {
      return instance
    }
    this.init(gtmKey)
    instance = this
  }

  init(gtmKey) {
    if (!AnalyticCore.checkInit()) {
      this.gtmKey = gtmKey
      if (!this.gtmKey) {
        throw new Error('missed NUXT_ENV_GTM_KEY')
      }
      this.initGTM()
    }
  }

  /**
   * Send event to data layer
   * [Official Documentation](https://developers.google.com/tag-manager/devguide?hl=ru#datalayer)
   * @param {Object} data
   */
  push(data) {
    try {
      if (isEmpty(data)) {
        throw new Error('Event data should be not empty')
      }
      if (!this.gtmKey) {
        if (process.env.PROD) return
        throw new Error('AnalyticCore must be inited')
      }
      if (process.browser) {
        window.dataLayer.push(data)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  static checkInit() {
    return !!Array.prototype.filter.call(
      document.getElementsByTagName('script'),
      (item) =>
        item.src.toString().includes('//www.googletagmanager.com/gtm.js')
    ).length
  }

  /**
   * Initialize GTM
   * Paste GTM initialize code here
   * [Official Documentation](https://developers.google.com/tag-manager/quickstart?hl=ru)
   */
  initGTM() {
    // * Paste GTM initialize code here from official documentation
    /* eslint-disable */
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l != 'dataLayer' ? `&l=${l}` : ''
      j.async = true
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', this.gtmKey)
    /* eslint-enable */
  }
}
