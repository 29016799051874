export default {
  fillPageMeta(state, payload) {
    state.pageMeta = payload
  },
  // ssr
  setCurrentPageMeta(state, payload) {
    state.currentPageMeta = payload
  },

  setPageContent(state, payload) {
    state.pageContent = payload
  },
}
