//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '~/components/UI/BaseButton.vue'

export default {
  name: 'CityConfirmPopup',
  components: {
    BaseButton,
  },
  props: {
    city: {
      type: String,
      default: '',
    },
  },
  methods: {
    onChangeCity() {
      this.$emit('open-picker')
    },
    onConfirmCity() {
      this.$emit('confirm', { city: this.city })
    },
  },
}
