export default {
  setPositions(state, payload) {
    state.positions = payload
  },
  setEmployments(state, payload) {
    state.typeEmployment = payload
  },
  setLoaded(state, payload) {
    state.isLoaded = !!payload
  },
  setVacancyCount(state, payload) {
    state.vacancyCount = payload
  },
  setVacancyTags(state, payload) {
    state.tags = payload
  },
  setCitiesWithVacancies(state, payload) {
    state.citiesWithVacancies = payload
  },
  setCityLoading(state, payload) {
    state.isCityLoading = payload
  },
}
