//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/icons/SVG/download.svg'
export default {
  name: 'MobileAppPanel',
  computed: {
    getUserAgent() {
      if (process.client) {
        const userAgent =
          window?.navigator?.userAgent ||
          window?.navigator?.vendor ||
          window.opera
        if (/android/i.test(userAgent)) {
          return 'Android'
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS'
        }
        return undefined
      }
      return undefined
    },
  },
}
