import { CREATE_CLAIM } from '~/services/api/graphql/mutation/createClaim'
import { CREATE_REPLY } from '~/services/api/graphql/mutation/createReply'

export default {
  createClaim({ rootState, commit }, payload) {
    const variables = {
      form: {
        ...payload,
        address: {
          city: rootState?.core?.city?.city || '',
          value: rootState?.core?.city?.city || '',
        },
        source: payload?.source || 'WEBSITE',
        sourceUrl: window.location.href,
      },
    }
    return new Promise((resolve, reject) => {
      this.$graphql.api_v2
        .request(CREATE_CLAIM, variables)
        .then((res) => {
          resolve()
        })
        .catch((e) => {
          const message = e?.message || ''
          if (message && message.includes('invalid phone number')) {
            this.$toast.error('Ошибка! Неправильный формат телефона')
          }
          console.error(e)
          reject(e)
        })
    })
  },
  createReply({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this.$graphql.api_v2
        .request(CREATE_REPLY, payload)
        .then((res) => {
          resolve(res?.createReply)
        })
        .catch((e) => {
          const message = e?.message || ''
          if (message && message.includes('invalid phone number')) {
            this.$toast.error('Ошибка! Неправильный формат телефона')
          } else {
            this.$toast.error('Ошибка! Попробуйте позже')
          }
          console.error(e)
          reject(e)
        })
    })
  },
}
