//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
      validator: (val) => ['info', 'confirm', 'submit'].includes(val),
    },
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscPressed)
  },
  mounted() {
    window.addEventListener('keyup', this.onEscPressed.bind(this))
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSubmit() {
      this.$emit('submit')
      this.onClose()
    },
    /**
     * Close modal on esc pressed
     * @param {KeyboardEvent} e - keyup event
     */
    onEscPressed(e) {
      // window.isEscapeLocked - lock escape on work with input fields
      if (e.key === 'Escape' && !window.isEscapeLocked) {
        this.onClose()
      }
    },
  },
}
