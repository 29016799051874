//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import { mapActions, mapState } from 'vuex'
import AnalyticCore from '~/services/analytics'
import utmMixin from '~/common/mixins/UtmMixin.js'
import BaseButton from '~/components/UI/BaseButton.vue'
import BaseCheckbox from '~/components/UI/form/BaseCheckbox.vue'
import BaseInput from '~/components/UI/form/BaseInput.vue'
import BaseTextarea from '~/components/UI/form/BaseTextarea.vue'
import ResponseForm from '~/forms/ResponseForm'
import { getCookie } from '~/common/helpers/cookie'
import smartCaptcha from '~/common/mixins/SmartCaptcha'
import LoadSpinner from '~/components/UI/LoadSpinner.vue'

export default {
  name: 'ModalResponse',
  components: {
    LoadSpinner,
    BaseButton,
    BaseInput,
    BaseCheckbox,
    BaseTextarea,
  },
  mixins: [utmMixin, smartCaptcha],
  props: {
    title: {
      type: String,
      default: 'Задать вопрос',
    },
    category: {
      type: String,
      default: 'OTHER',
    },
    type: {
      type: String,
      default: 'contact',
      validator: (value) => {
        const result = ['contact', 'reply'].includes(value)
        if (!result) {
          return value
        }
        return true
      },
    },
    vacancy: {
      type: Object,
      default: () => {},
    },
    city: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'не указано',
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        info: '',
        message: '',
        wish: '',
        agreeBox: false,
      },
      errors: {
        phone: '',
        name: '',
        info: '',
        message: '',
        wish: '',
        agreeBox: '',
      },
      isDisabledButton: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
  },
  methods: {
    ...mapActions('forms', ['createClaim', 'createReply']),
    onInputField(field) {
      this.$set(this.errors, field, '')
    },
    onBlurField(field) {
      ResponseForm.validateAt(field, this.form)
        .then(() => {
          this.$set(this.errors, field, '')
        })
        .catch((err) => {
          this.$set(this.errors, field, err[field])
        })
    },
    close() {
      this.$emit('close', false)
    },
    sendReplyAnalytic(e) {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'form_submit',
          eventAction: AnalyticCore.EVENT_ACTION.submit,
          eventCategory: AnalyticCore.EVENT_CATEGORY.conversion,
          position: get(this.vacancy, 'name', ''),
          city: this.localCity?.city || '',
          vacancyCity: get(this.vacancy, 'address.city', ''),
          manager: get(this.vacancy, 'contacts.fullName', ''),
          employer: get(this.vacancy, 'employer.name', ''),
          eventContent: get(this.vacancy, 'id', ''),
          path: window.location.href,
          ...this.utm,
          eventLabel: 'form',
          eventContext: this.form,
          eventLocation: 'vacancies',
          replyId: e || '',
        })
      }
    },
    sendContactAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'contacts_submit',
          eventAction: AnalyticCore.EVENT_ACTION.submit,
          eventCategory: AnalyticCore.EVENT_CATEGORY.conversion,
          city: this.localCity?.city || '',
          eventContent: this.form,
          path: window.location.href,
          ...this.utm,
          eventLabel: 'form',
          eventContext: 'contactsForm',
          eventLocation: 'contacts',
        })
      }
    },
    submit() {
      const tokenCaptcha = getCookie('rabotut:user:captchaToken')
      this.errors = ResponseForm.validate(this.form)
      if (!Object.keys(this.errors).length && !this.isLoading) {
        if (!tokenCaptcha) {
          this.isDisabledButton = true
          if (this.type === 'contact') {
            this.renderCaptcha(this.onSubmitContact)
          } else if (this.type === 'reply') {
            this.renderCaptcha(this.onSubmitReply)
          }
        } else {
          console.log('tokenCaptcha', !!tokenCaptcha)
          if (this.type === 'contact') {
            this.onSubmitContact()
          } else if (this.type === 'reply') {
            this.onSubmitReply()
          }
        }
      }
    },

    onSubmitContact() {
      this.isLoading = true
      this.isDisabledButton = false
      this.sendContactAnalytic()
      let tel = this.form.phone.replace(/[()\s-+]/g, '')
      tel = parseInt(tel)

      const comment = this.form?.message || ''

      let sendTo = ''
      if (this.category === 'WORK_WITH_US') {
        sendTo = 'hr@hr-team.pro'
      }

      const payload = {
        sendTo,
        category: this.category,
        contacts: {
          fullName: this.form.name,
          phone: tel.toString(),
          email: '',
        },
        position: this.vacancy?.position?.name || '',
        userComment: comment,
        userWish: '',
        utm: JSON.parse(getCookie('rabotut:utm')),
        userContent: this.form?.wish || '',
      }
      this.createClaim(payload)
        .then(() => {
          this.$emit('close', false)
          if (this.callback) {
            this.callback()
          }
        })
        .catch((fieldErr) => {
          console.error(fieldErr)
        })
        .finally(() => {
          this.isDisabledButton = false
          this.isLoading = false
        })
    },
    onSubmitReply() {
      this.isLoading = true
      this.isDisabledButton = false
      const currentUrl = window.location.href
      const match = currentUrl.match(/click_id=([^&]+)/)
      const clickId = match ? match[1] : null
      let tel = this.form.phone.replace(/[()\s-+]/g, '')
      tel = parseInt(tel)
      const comment = `${this.form.message}\n${this.form.wish}`
      const payload = {
        form: {
          contacts: {
            fullName: this.form.name,
            phone: String(tel),
          },
          address: {
            city: this.localCity?.city || '',
            value: this.localCity?.city || '',
          },
          source: 'WEBSITE',
          vacancyId: +this.vacancy.id,
          positionId: +this.vacancy?.position?.id || this.vacancy?.position_id,
          ownerId: +this.vacancy?.manager?.id || this.vacancy?.owner_id,
          companyId:
            +this.vacancy?.manager?.company_id ||
            this.vacancy?.company_id ||
            +this.vacancy?.manager?.companyId,
          departmentId:
            +this.vacancy?.manager?.department_id ||
            this.vacancy?.department_id ||
            +this.vacancy?.manager?.departmentId,
          userComment: comment,
          utm: JSON.parse(getCookie('rabotut:utm')) || '',
          cityAdsClick: clickId,
          actionPayClick: null,
        },
      }
      this.createReply(payload)
        .then((res) => {
          this.sendReplyAnalytic(res?.id)
          this.$emit('close', false)
          if (this.callback) {
            this.callback()
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
