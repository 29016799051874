import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _150bfccc = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _7b8ad8ba = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _4eee96d4 = () => interopDefault(import('../pages/for-partners/index.vue' /* webpackChunkName: "pages/for-partners/index" */))
const _350853bb = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _6fcb5c28 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _d0e88888 = () => interopDefault(import('../pages/m/index.vue' /* webpackChunkName: "pages/m/index" */))
const _602cc381 = () => interopDefault(import('../pages/moscow/index.vue' /* webpackChunkName: "pages/moscow/index" */))
const _4c52b1dc = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _3dd147de = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _2c345707 = () => interopDefault(import('../pages/rtz/index.vue' /* webpackChunkName: "pages/rtz/index" */))
const _24cb8704 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _10d5c481 = () => interopDefault(import('../pages/training/index.vue' /* webpackChunkName: "pages/training/index" */))
const _35586aa8 = () => interopDefault(import('../pages/vacancies/index.vue' /* webpackChunkName: "pages/vacancies/index" */))
const _2049fcc2 = () => interopDefault(import('../pages/m/city/_id/index.vue' /* webpackChunkName: "pages/m/city/_id/index" */))
const _e2f0f1ae = () => interopDefault(import('../pages/m/city/_id/vacancies/index.vue' /* webpackChunkName: "pages/m/city/_id/vacancies/index" */))
const _4af234d8 = () => interopDefault(import('../pages/m/city/_id/vacancies/_id/index.vue' /* webpackChunkName: "pages/m/city/_id/vacancies/_id/index" */))
const _f62472ae = () => interopDefault(import('../pages/dynamic/_name/index.vue' /* webpackChunkName: "pages/dynamic/_name/index" */))
const _68f58370 = () => interopDefault(import('../pages/static/_name/index.vue' /* webpackChunkName: "pages/static/_name/index" */))
const _1f2bf193 = () => interopDefault(import('../pages/vacancies/_id/index.vue' /* webpackChunkName: "pages/vacancies/_id/index" */))
const _91a93044 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8a16ea2a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company",
    component: _150bfccc,
    name: "company"
  }, {
    path: "/contacts",
    component: _7b8ad8ba,
    name: "contacts"
  }, {
    path: "/for-partners",
    component: _4eee96d4,
    name: "for-partners"
  }, {
    path: "/healthcheck",
    component: _350853bb,
    name: "healthcheck"
  }, {
    path: "/help",
    component: _6fcb5c28,
    name: "help"
  }, {
    path: "/m",
    component: _d0e88888,
    name: "m"
  }, {
    path: "/moscow",
    component: _602cc381,
    name: "moscow"
  }, {
    path: "/partners",
    component: _4c52b1dc,
    name: "partners"
  }, {
    path: "/promo",
    component: _3dd147de,
    name: "promo"
  }, {
    path: "/rtz",
    component: _2c345707,
    name: "rtz"
  }, {
    path: "/sitemap",
    component: _24cb8704,
    name: "sitemap"
  }, {
    path: "/training",
    component: _10d5c481,
    name: "training"
  }, {
    path: "/vacancies",
    component: _35586aa8,
    name: "vacancies"
  }, {
    path: "/m/city/:id",
    component: _2049fcc2,
    name: "m-city-id"
  }, {
    path: "/m/city/:id?/vacancies",
    component: _e2f0f1ae,
    name: "m-city-id-vacancies"
  }, {
    path: "/m/city/:id?/vacancies/:id",
    component: _4af234d8,
    name: "m-city-id-vacancies-id"
  }, {
    path: "/dynamic/:name",
    component: _f62472ae,
    name: "dynamic-name"
  }, {
    path: "/static/:name",
    component: _68f58370,
    name: "static-name"
  }, {
    path: "/vacancies/:id",
    component: _1f2bf193,
    name: "vacancies-id"
  }, {
    path: "/",
    component: _91a93044,
    name: "index"
  }, {
    path: "/*",
    component: _8a16ea2a,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
