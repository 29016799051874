import axios from 'axios'
import { pickBy } from 'lodash'

const LOKI_URL = process?.env?.APP_LOKI_HOST;

const LOKI_LABEL = process?.env?.APP_LOKI_LABEL;

export const LOG_LEVEL = {
  INFO: 'info',
  ERROR: 'error',
  DEBUG: 'debug',
  WARNING: 'warning',
}

export const payloadConstructor = (e) => {
  const data = {
    level: e?.level,
    message: e?.message,
    component: e?.component,
    field: e?.field,
  }

  const preparedData = pickBy(data)

  console.log('preparedData', preparedData)

  let payloadString = ''

  Object.entries(preparedData).map(([key, value]) => {
    if (key && value) {
      const val = `${key?.replace(/ /g, '_')}=${value?.replace(/ /g, '_')}`
      payloadString += ' ' + val
    }
    return null
  })
  const nanoSec = Date.now() * 1000000
  const dateString = nanoSec?.toString()

  return {
    streams: [
      {
        stream: {
          host: LOKI_LABEL,
        },
        values: [[dateString, payloadString]],
      },
    ],
  }
}

export class Logger {
  static instance

  static get() {
    if (!Logger.instance) {
      Logger.instance = new Logger()
    }
    return Logger.instance
  }

  send(data) {
    const requestPayload = payloadConstructor(data)
    console.log('requestPayload', requestPayload)
    return axios.post(LOKI_URL, requestPayload)
  }
}
