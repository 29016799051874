export default () => {
  if (process.client) {
    const separatorString = `<!-- Integration: cityAds.html-->`
    document.body.insertAdjacentHTML('beforeend', separatorString)
    const content = `<img
  src="//p.cityadstrack.com/analytics.gif"
  width="0"
  height="0"
  onerror="
var s=document.createElement('script');
s.src=this.src+'?iserror',s.async=!0,document.body.appendChild(s);"
/>
`
    if (content) {
      document.body.insertAdjacentHTML('beforeend', content)
    }
  }
}
