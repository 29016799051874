import { gql } from 'nuxt-graphql-request'

export const BANNER_BY_SLOT = gql`
  query bannerBySlot($slot: String!) {
    bannerBySlot(slot: $slot) {
      ... on BannerType {
        type
        desktopUrl
        url
        slot
        id
        href
        name
        description
        srcSet
        mobileUrl
        tabletUrl
        content
      }
    }
  }
`

export default BANNER_BY_SLOT
