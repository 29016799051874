import values from 'lodash/values'
import set from 'lodash/set'
import forEach from 'lodash/forEach'

// Валидация
export const yupToFormErrors = (yupErrors) => {
  let errors = {}
  const yupErrorsValues = values(yupErrors.inner)

  const formatError = (err) => {
    if (!errors[err.path]) {
      errors = set(errors, err.path, err.message)
    }
  }

  forEach(yupErrorsValues, formatError)

  return errors
}
