//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseInput from '~/components/UI/form/BaseInput.vue'
import BaseModal from '~/components/UI/BaseModal.vue'
import LoadSpinner from '~/components/UI/LoadSpinner.vue'

export default {
  name: 'CityPicker',
  components: {
    LoadSpinner,
    BaseModal,
    BaseInput,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    city: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      options: [],
    }
  },
  computed: {
    ...mapState('dictionary', ['citiesWithVacancies', 'isCityLoading']),
    computedStatus() {
      if (this.message) {
        return 'error'
      } else {
        return 'info'
      }
    },
  },
  mounted() {
    this.loadCitiesWithVacancies().then((res) => {
      this.options = res
    })
  },
  methods: {
    ...mapActions('user', ['searchCities']),
    ...mapActions('dictionary', ['loadCitiesWithVacancies']),
    ...mapMutations('dictionary', ['setCitiesWithVacancies']),
    closeModal() {
      this.search = ''
      this.$emit('hide')
    },
    onChangeCity(item) {
      this.$emit('confirm', item)
    },
    onSearchCity(event) {
      this.search = event || ''
      if (this.search.length >= 2) {
        this.searchCities(this.search).then((res) => {
          this.options = res
        })
      }
    },
    onInputSearch: debounce(function (e) {
      this.onSearchCity(e)
    }, 300),
  },
}
